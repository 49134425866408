import { createContext, useContext } from 'react'

export type ProductCollectionActionOptions = {
  onSwipeLeft: () => void
  onSwipeRight: () => void
  onAddToCart: (sapId: string) => void
  onAddToTemplate: (sapId: string) => void
  onLinkClick: (sapId: string) => void
}

const initialEmptyFunction = () => {
  // empty function, which will be called if function was not provided
}

const defaultValue = {
  onSwipeLeft: initialEmptyFunction,
  onSwipeRight: initialEmptyFunction,
  onAddToCart: initialEmptyFunction,
  onAddToTemplate: initialEmptyFunction,
  onLinkClick: initialEmptyFunction,
}
export const ProductCollectionActionContext =
  createContext<Partial<ProductCollectionActionOptions> | null>(defaultValue)
export const ProductCollectionActionProvider = ProductCollectionActionContext.Provider

export const useProductCollectionActions = (): ProductCollectionActionOptions => {
  const context = useContext(ProductCollectionActionContext)
  return {
    onSwipeLeft: context?.onSwipeLeft ?? initialEmptyFunction,
    onSwipeRight: context?.onSwipeRight ?? initialEmptyFunction,
    onAddToCart: context?.onAddToCart ?? initialEmptyFunction,
    onAddToTemplate: context?.onAddToTemplate ?? initialEmptyFunction,
    onLinkClick: context?.onLinkClick ?? initialEmptyFunction,
  }
}
