import { useObservableState } from 'observable-hooks'
import { debounceTime, distinctUntilChanged } from 'rxjs'

export const useSearchText = () => {
  const [searchText, setSearchText] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  )

  return { searchText, setSearchText }
}
