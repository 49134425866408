import { IMGPROXY_URL } from '@obeta/utils/lib/config'
import { useRemToPx } from './useRemToPixel'
import { useEffect, useRef } from 'react'
import { trackCustom } from '@obeta/utils/lib/tracking'

const RESIZING_TYPE = 'fit'

// TODO: magic numbers? why exactly these values?
export const DEFAULT_WIDTH_IN_REM_MOBILE = 45
export const DEFAULT_WIDTH_IN_REM_TABLET = 51
export const DEFAULT_WIDTH_IN_REM_TABLET_WIDE = 22
export const DEFAULT_WIDTH_IN_REM_DESKTOP = 25

export const useImgProxyUrls = (
  url: string,
  mobileWidthRem = DEFAULT_WIDTH_IN_REM_MOBILE,
  tabletWidthRem = DEFAULT_WIDTH_IN_REM_TABLET,
  tabletWideWidthRem = DEFAULT_WIDTH_IN_REM_TABLET_WIDE,
  desktopWidthRem = DEFAULT_WIDTH_IN_REM_DESKTOP
) => {
  const didTrackMissingUrlAction = useRef(false)
  useEffect(() => {
    if (!url && !didTrackMissingUrlAction.current) {
      trackCustom('image-with-empty-url-rendered')
      didTrackMissingUrlAction.current = true
    }
  }, [url])

  const mobileWidthPx = Math.round(useRemToPx(mobileWidthRem))
  const tabletWidthPx = Math.round(useRemToPx(tabletWidthRem))
  const tabletWideWidthPx = Math.round(useRemToPx(tabletWideWidthRem))
  const desktopWidthPx = Math.round(useRemToPx(desktopWidthRem))
  const path = `/plain/${url}`

  const imgProxyUrlMobile = `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${
    mobileWidthPx * 2
  }${path}`
  const imgProxyUrlTablet = `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${
    tabletWidthPx * 2
  }${path}`
  const imgProxyUrlTabletWide = `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${
    tabletWideWidthPx * 2
  }${path}`
  const imgProxyUrlDesktop = `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${
    desktopWidthPx * 2
  }${path}`

  return {
    imgProxyUrlMobile,
    imgProxyUrlTablet,
    imgProxyUrlTabletWide,
    imgProxyUrlDesktop,
  }
}
