import { useMemo, useRef } from 'react'

/**
 * @param arr
 * @param filterFn
 * @param compareFn
 * @returns
 *
 * Allows to split data on 2 groups by condition
 * (filterFn param. if fn returns "-1" put data in first groups. if fn returns "1" put data in second groups).
 * Then sorts data in each groups (compareFn).
 * Then merges data back into one group.
 *
 * Example:
 *
 * given you have the following list:
 *  WER Cart (0)
 *  DEF Cart (5)
 *  DEF2 Cart (25)
 *  AbC Cart (0)
 *
 * in brackets is the count of articles
 * now we first sort them, so all cart with at least one article come first
 * this yields:
 *  DEF Cart (5)
 *  DEF2 Cart (25)
 *  WER Cart (0)
 *  AbC Cart (0)
 *
 * now within the groups (first group are carts with at least 1 article, second group are empty carts) we sort by name
 * this yields:
 *  DEF Cart (5)
 *  DEF2 Cart (25)
 *  AbC Cart (0)
 *  WER Cart (0)
 *
 *
 */
export const useDoubleSort = <T>(
  arr: T[],
  filterFn: (a: T) => 1 | -1,
  compareFn: (a: T, b: T) => number
) => {
  const filterFnRef = useRef(filterFn)
  const compareFnRef = useRef(compareFn)

  return useMemo(() => {
    const groups = arr.reduce<T[][]>(
      (acc, current) => {
        const sortOrder = filterFnRef.current(current)
        if (sortOrder > 0) {
          acc[1].push(current)
        } else if (sortOrder < 0) {
          acc[0].push(current)
        }

        return acc
      },
      [[], []]
    )

    const sortedGroups = groups.map((group) => {
      return group.sort(compareFnRef.current)
    })

    return sortedGroups.flat()
  }, [arr])
}
