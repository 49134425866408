import { PopoverOrigin } from '@mui/material'
import {
  DropdownButtonWithMenu,
  IDropdownButtonWithMenuProps,
} from '../dropdown-button/DropdownButtonWithMenu'
import { PopoverMenuClasses } from '../dropdown-button/PopoverDropdownMenu'
import { Scrollbar } from '../scrollbar/Scrollbar'
import clsx from 'clsx'
import styles from './ArticleDropdown.module.scss'

export const articleDropdownMenuClasses = (mobile: boolean, menuClasses?: PopoverMenuClasses) => {
  return {
    ...menuClasses,
    paper: clsx(styles.root, { [styles.paperMobile]: mobile }, menuClasses?.paper),
    menuList: clsx(styles.menuList, { [styles.menuListMobile]: mobile }, menuClasses?.menuList),
    menuItem: clsx(styles.menuItem, { [styles.menuItemMobile]: mobile }, menuClasses?.menuItem),
  }
}

export const articleAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const articleTrasnformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

const originMobile: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

/**
 * @deprecated - used only in one place.
 *  the only reusable parts here are "articleDropdownMenuClasses" and origins (transform and anchor)
 * @param props
 * @returns
 */

export const ArticleDropdown = <Option extends { id: string | number }>(
  props: IDropdownButtonWithMenuProps<Option>
) => {
  const { popover, ...rest } = props
  const {
    menuClasses,
    anchorOrigin: _anchorOrigin = articleAnchorOrigin,
    transformOrigin: _transformOrigin = articleTrasnformOrigin,
    ...restPopover
  } = popover

  const mobile = Boolean(popover.mobile)

  const anchorOrigin = mobile ? originMobile : _anchorOrigin
  const transformOrigin = mobile ? originMobile : _transformOrigin

  return (
    <DropdownButtonWithMenu
      popover={{
        anchorOrigin,
        transformOrigin,
        ScrollbarComponent: Scrollbar,
        menuClasses: {
          ...menuClasses,
          ...articleDropdownMenuClasses(Boolean(popover.mobile), menuClasses),
        },
        ...restPopover,
      }}
      {...rest}
    />
  )
}
