import { SvgIcon } from '@mui/material'
import { WithBreakpoints } from '@obeta/models/lib/models/Components'
import { useTranslation } from 'react-i18next'
import { PopoverProps } from '../dropdown-button/PopoverDropdownMenu'
import { DropdownButton } from '../dropdown-button'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { ReactComponent as CartMoveIcon } from 'assets/icon/designsystem/shopping_cart_move.svg'
import { noop } from '@obeta/data/lib/actions/common-actions'
import { BaseEntity, EntityDropdown } from '../entity-dropdown/EntityDropdown'
import { useDoubleSort } from '@obeta/data/lib/hooks/useDoubleSort'
import { namesSortComparator } from '@obeta/utils/lib/namesSortComparator'

interface IDropdownCartsBaseProps<Cart extends BaseEntity> extends WithBreakpoints<'mobile'> {
  dropdown: Pick<PopoverProps, 'onClose' | 'open' | 'anchorEl' | 'anchorOrigin' | 'transformOrigin'>
  carts: Cart[]
  buttonId?: string
  onOptionSelected?: (ShoppingCartV2: Cart) => void
}

export const DropdownCartsBase = <Cart extends BaseEntity>(
  props: IDropdownCartsBaseProps<Cart>
) => {
  const { mobile, dropdown, carts, buttonId, onOptionSelected } = props

  const sortedCarts = useDoubleSort(
    carts,
    (t) => {
      return t.count > 0 ? -1 : 1
    },
    (a, b) => {
      return namesSortComparator(a.name, b.name)
    }
  )

  const { t } = useTranslation()

  return (
    <EntityDropdown
      buttonId={buttonId}
      createEntityTitle={t<string>('ARTICLE_DETAIL.CREATE_NEW_SHOPPING_CART')}
      mobile={mobile}
      options={sortedCarts}
      onOptionSelected={onOptionSelected || noop}
      createEntityDisabled={true}
      onEntityCreated={(name) => {
        throw new Error('onCartCreated: not implemented')
      }}
      {...dropdown}
    />
  )
}

interface IDropdownCartsProps<Cart extends BaseEntity>
  extends Omit<IDropdownCartsBaseProps<Cart>, 'dropdown' | 'dropdownId'>,
    Pick<IDropdownCartsBaseProps<Cart>['dropdown'], 'anchorOrigin' | 'transformOrigin'> {
  disabled?: boolean
  selectedArticleCount?: number
  desktop: boolean
  tablet: boolean
}

export const DropdownCarts = <Cart extends BaseEntity>(props: IDropdownCartsProps<Cart>) => {
  const {
    desktop,
    tablet,
    mobile,
    selectedArticleCount,
    disabled,
    carts,
    onOptionSelected,
    anchorOrigin,
    transformOrigin,
  } = props
  const { handleClick, ...dropdown } = usePopoverState()
  const buttonId = 'cart-button'

  const { t } = useTranslation()

  return (
    <DropdownButton
      title={
        desktop || tablet
          ? t('SHOPPING_CART.ACTIONS.MOVE_desktop', { selectedArticleCount })
          : ` (${selectedArticleCount})`
      }
      buttonId={buttonId}
      dropdownId={'cart-menu'}
      expanded={dropdown.open}
      disabled={disabled}
      onClick={handleClick}
      startIcon={<SvgIcon component={CartMoveIcon} />}
      justifyContent="spaceBetween"
      withEndIcon={!mobile && !tablet}
    >
      <DropdownCartsBase
        onOptionSelected={onOptionSelected}
        carts={carts}
        mobile={mobile}
        dropdown={{
          anchorEl: mobile ? null : dropdown.anchorEl,
          onClose: dropdown.onClose,
          open: dropdown.open,
          anchorOrigin,
          transformOrigin,
        }}
      />
    </DropdownButton>
  )
}
