import Fuse from 'fuse.js'
import { useMemo, useState } from 'react'
import { useDebouncedEffect } from '../hooks/useDebouncedEffect'

interface Result<T> {
  result: T[]
}

/**
 * Uses "searchText" to find entities in "entities" array.
 * "searchText" is compared with properties values in entity.
 * "keys" array defines what properties must be compared with "searchText"
 * @param entities
 * @param searchText
 * @param keys
 * @returns filtered entities
 */
export const useLocalSearch = <Model>(
  entities: Model[],
  searchText: string,
  keys: string[] = []
): Result<Model> => {
  const fuse = useMemo(() => {
    const index = Fuse.createIndex(keys, entities)
    return new Fuse(
      entities,
      {
        keys,
        includeScore: true,
        minMatchCharLength: 2,
        threshold: 0.2,
        distance: 200,
        ignoreLocation: true,
      },
      index
    )
  }, [keys, entities])

  const [filteredEntities, setFilteredEntities] = useState<Model[]>(entities)

  useDebouncedEffect(
    () => {
      if (!searchText || searchText.length < 2) return setFilteredEntities(entities)

      const tokens = searchText.split(' ').filter(Boolean)

      const searchConfig = {
        $and: tokens.map((token) => ({
          $or: keys.map((key) => ({ [key]: token })),
        })),
      }

      const result = fuse.search(searchConfig)
      setFilteredEntities(result.map(({ item }) => item))
    },
    [searchText, fuse, keys, entities],
    300
  )

  return { result: filteredEntities }
}
