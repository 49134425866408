import type { PopoverActions } from '@mui/material'
import { useCallback, useRef, useState } from 'react'
import { ICreateEntityButtontProps } from '../create-entity-button/CreateEntityButton'
import {
  IPopoverDropdownMenuProps,
  PopoverDropdownMenu,
  PopoverMenuClasses,
  PopoverProps,
} from '../dropdown-button/PopoverDropdownMenu'
import { TypographyEllipsis } from '../typography/TypographyEllipsis'
import { Scrollbar } from '../scrollbar/Scrollbar'
import {
  articleAnchorOrigin,
  articleDropdownMenuClasses,
  articleTrasnformOrigin,
} from '../article-dropdown/ArticleDropdown'
import clsx from 'clsx'
import styles from './EntityDropdown.module.scss'

// Hooks
import { useWindowScroll } from '@obeta/data/lib/hooks/useWindowEvent'

// UI
import { SearchField } from '../search-field/SearchField'

export interface DefaultBaseEntity1 {
  id: string | number
}
export interface BaseEntity extends DefaultBaseEntity1 {
  count: number
  name: string
}

interface IBaseEntityDropdownProps<Option extends DefaultBaseEntity1>
  extends Pick<
      IPopoverDropdownMenuProps<Option>,
      'topAdornment' | 'options' | 'buttonId' | 'onOptionSelected' | 'id' | 'bottomAdornment'
    >,
    Pick<PopoverProps, 'onClose' | 'open' | 'anchorEl' | 'transformOrigin' | 'anchorOrigin'> {
  mobile: boolean
  formatOption: (opt: Option) => React.ReactChild
  popoverAction?: React.RefObject<PopoverActions>
  classes?: PopoverMenuClasses
}

interface IEntityDropdownProps<Option extends BaseEntity>
  extends Omit<IBaseEntityDropdownProps<Option>, 'popoverAction' | 'formatOption'>,
    Pick<ICreateEntityButtontProps, 'onEntityCreated'> {
  createEntityTitle: string
  createEntityDisabled?: boolean
  formatOption?: IBaseEntityDropdownProps<Option>['formatOption']
}

export const EntityDropdownSearchField: React.FC<{
  mobile: boolean
  onTextUpdated: (text: string) => void
}> = (props) => {
  const [searchText, setSearchText] = useState<string>('')

  const onSearch = useCallback(
    (value: string) => {
      setSearchText(value)
      props.onTextUpdated(value)
    },
    [props]
  )

  return (
    <SearchField
      className={clsx({ [styles.mobileSearchField]: props.mobile })}
      value={searchText}
      onChange={onSearch}
      onReset={() => onSearch('')}
    />
  )
}

export const BaseEntityDropdown = <Option extends DefaultBaseEntity1>(
  props: IBaseEntityDropdownProps<Option>
) => {
  const {
    topAdornment,
    buttonId,
    onOptionSelected,
    options,
    mobile,
    anchorOrigin = articleAnchorOrigin,
    transformOrigin = articleTrasnformOrigin,
    id,
    popoverAction,
    bottomAdornment,
    formatOption,
    classes,
    ...dropdown
  } = props
  const menuClasses = articleDropdownMenuClasses(mobile, {
    ...classes,
    paper: clsx(styles.paper, classes?.paper),
    menuItem: clsx(styles.menuItem, classes?.menuItem),
  })

  return (
    <PopoverDropdownMenu
      mobile={mobile}
      action={popoverAction}
      id={id}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      topAdornment={topAdornment}
      ScrollbarComponent={Scrollbar}
      withBackdrop={mobile}
      bottomAdornment={
        bottomAdornment && (
          <div className={clsx({ [styles.footerMobile]: mobile })}>{bottomAdornment}</div>
        )
      }
      buttonId={buttonId}
      menuClasses={menuClasses}
      onOptionSelected={(option) => {
        onOptionSelected(option)
        dropdown.onClose()
      }}
      options={options}
      formatOption={formatOption}
      open={dropdown.open}
      onClose={dropdown.onClose}
      anchorEl={dropdown.anchorEl}
    />
  )
}

export const EntityDropdown = <Option extends BaseEntity>(props: IEntityDropdownProps<Option>) => {
  const {
    onEntityCreated,
    createEntityTitle,
    createEntityDisabled,
    mobile,
    formatOption = (option) => {
      return (
        <TypographyEllipsis variant="body" noWrap>
          {`${option.name} (${option.count})`}
        </TypographyEllipsis>
      )
    },
    ...dropdown
  } = props

  const popoverAction = useRef<PopoverActions>(null)

  // Close dropdown on window scroll
  useWindowScroll(() => {
    dropdown.onClose()
  }, [dropdown])

  return (
    <BaseEntityDropdown
      {...dropdown}
      mobile={mobile}
      formatOption={formatOption}
      popoverAction={popoverAction}
      bottomAdornment={
        undefined
        // THIS REMOVAL IS TEMPORARY UNTIL FUNCTIONALITY IS ADDED
        // <CreateEntityButton
        //   title={createEntityTitle}
        //   mobile={mobile}
        //   disabled={createEntityDisabled}
        //   onModeChange={() => {
        //     popoverAction.current?.updatePosition()
        //   }}
        //   onEntityCreated={onEntityCreated}
        // />
      }
    />
  )
}
