import { useEffect } from 'react'

/**
 * Reusable window event listener hook.
 * @param event Window event
 * @param callback Callback function
 * @param dependencies Dependencies
 */
export const useWindowEvent = (event, callback, dependencies) => {
  useEffect(() => {
    window.addEventListener(event, callback)
    return () => window.removeEventListener(event, callback)
  }, [event, callback, dependencies])
}

/**
 * Reusable window scroll event listener hook.
 * @param callback Callback function
 * @param dependencies Dependencies
 * @returns Window scroll event
 */
export const useWindowScroll = (callback, dependencies) => {
  return useWindowEvent('scroll', callback, dependencies)
}
