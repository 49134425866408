export const namesSortComparator = (name1: string, name2: string) => {
  const nameA = name1.toUpperCase() // ignore upper and lowercase
  const nameB = name2.toUpperCase() // ignore upper and lowercase
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  // names must be equal
  return 0
}
