import { OfferAmount } from '@obeta/schema'
import { MultiSelectionNumber, MultiSelectionString } from '../Meta/Meta'

export interface DropDownTemplatesOffer {
  offerId: string
  offerName: string
  selectedItemCount: number
  multiSelection: MultiSelectionNumber
  modifiedOfferItemAmounts: OfferAmount[]
}

export interface DropDownOriginCartTemplate {
  cartTemplateId: string
  cartTemplateName: string
  selectedItemCount: number
  multiSelection: MultiSelectionString
}

export interface CartTemplateItemInput {
  productId: string
  amount: number
}

// Determines which dispatch should be called inside DropdownTemplates component (Add article to template or add cart templates to cart template)
export enum DropdownTemplatesType {
  ADD_ARTICLES = 'ADD_ARTICLE',
  ADD_TEMPLATES = 'ADD_TEMPLATE',
  ADD_TEMPLATE_ITEMS = 'ADD_TEMPLATE_ITEMS',
  ADD_OFFER = 'ADD_OFFER',
  ADD_ORDER_ITEMS = 'ADD_ORDER_ITEMS',
  ADD_ORDER_ITEMS_IN_ARREARS = 'ADD_ORDER_ITEMS_IN_ARREARS',
}

export enum Tab {
  ALL,
  FAVORITES,
  PRIVATE,
  PUBLIC,
}

export enum SortBy {
  LAST_USED = 'LAST_USED',
  LAST_UPDATED = 'LAST_UPDATED',
  ALPHABETICAL = 'ALPHABETICAL',
  PRIVATE_TEMPLATES = 'PRIVATE_TEMPLATES',
  PUBLIC_TEMPLATES = 'PUBLIC_TEMPLATES',
}
